import axios from "axios";

class DAO {
  fetchLocations = () => {
    return axios({
      method: "get",
      url:
        "https://uww3ujo07d.execute-api.ap-south-1.amazonaws.com/v1/sthala/getjson?property_id=f5fa19e3-e1ef-49b4-a790-5d43e6e9aa4a&filter=location",
      // transformRequest: [function (data, headers) {
      //   delete headers['common']['content-type'];
      //   return data;
      // }]
    });
  };

  uploadLocation = data => {
    return axios({
      method: "post",
      url:
        "https://uww3ujo07d.execute-api.ap-south-1.amazonaws.com/v1/sthala/getjson?property_id=f5fa19e3-e1ef-49b4-a790-5d43e6e9aa4a&filter=location",
      data,
      // transformRequest: [function (data, headers) {
      //   delete headers['common']['content-type'];
      //   return data;
      // }]
    });
  };

  suggestPlaces = (searchString, center) => {
    return axios({
      method: "get",
      url: `https://b6rel7mym2.execute-api.ap-south-1.amazonaws.com/dev1/places/autocomplete?searchString=${searchString}&center=${center}`,
    });
  };

  getPlace = placeId => {
    return axios({
      method: "get",
      url: `https://b6rel7mym2.execute-api.ap-south-1.amazonaws.com/dev1/places?placeId=${placeId}`,
    });
  };

  getDistance = (origin, destination) => {
    return axios({
      method: "get",
      url: `https://b6rel7mym2.execute-api.ap-south-1.amazonaws.com/dev1/places/get-distance?origin=${origin}&destination=${destination}`,
    });
  };
}

export default DAO;
