import React, { Component } from "react";
import { Player } from "video-react";
import { Button, Image, Modal } from "semantic-ui-react";

import "./theme.scss";

class ImageViewer extends Component {
  state = {
    imageIndex: 0,
  };

  componentWillReceiveProps(newProps) {
    this.setState({ imageIndex: newProps.imageIndex });
  }

  navigateLeft = () => {
    this.setState({ imageIndex: this.state.imageIndex - 1 });
  };

  navigateRight = () => {
    this.setState({ imageIndex: this.state.imageIndex + 1 });
  };

  getCarouselControls = () => {
    if (this.props.imagesList.length < 2) return;
    return (
      <div>
        <Button
          circular
          icon="chevron left"
          size={"small"}
          onClick={this.navigateLeft}
          disabled={this.state.imageIndex < 1}
        />
        <Button
          circular
          icon="chevron right"
          size={"small"}
          onClick={this.navigateRight}
          disabled={this.state.imageIndex > this.props.imagesList.length - 2}
        />
      </div>
    );
  };

  render() {
    const { open, imageIndex, imagesList, onClose } = this.props;

    if (!imagesList[imageIndex]) return null;

    return (
      <Modal
        dimmer={"blurring"}
        open={open}
        onClose={onClose}
        style={{ marginTop: "0" }}
        className="image-viewer"
      >
        <Modal.Header>
          <p>
            {imagesList[this.state.imageIndex].name
              ? imagesList[this.state.imageIndex].name
              : ""}
          </p>
        </Modal.Header>
        <Modal.Content>
          {imagesList[this.state.imageIndex].type === "video" ? (
            <div className="video-player">
              <Player
                poster={imagesList[this.state.imageIndex].url}
                src={imagesList[this.state.imageIndex].videoUrl}
                fluid
              />
            </div>
          ) : (
            <Image
              src={imagesList[this.state.imageIndex].url}
              className={"image-on-display"}
            />
          )}
        </Modal.Content>
        <Modal.Actions>
          {this.getCarouselControls()}
          <Button onClick={onClose} content={"Close"} />
        </Modal.Actions>
      </Modal>
    );
  }
}

export default ImageViewer;
