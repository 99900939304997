import React, { Component } from "react";
import Helmet from "react-helmet";
import { Image, Loader, Modal } from "semantic-ui-react";
import { Icon, Button, Popup as HoverLabel } from "semantic-ui-react";
import { GatsbySeo } from "gatsby-plugin-next-seo";
import "mapbox-gl/dist/mapbox-gl.css";

import MapSidebar from "src/components/MapSidebar";
import HelpButton from "src/components/HelpButton";
import ResetButton from "src/components/ResetButton";
import NavigateButton from "src/components/NavigateButton";
import SearchPanel from "src/components/SearchPanel";

import Markers from "src/components/Markers";

import logo from "src/images/sthalawordlogo.png";
import mobileLogo from "src/images/sthalaicon.png";

import "./theme.scss";

// Webpack and Mapbox-GL don't play together
// This creates a mock component unless rendered on the browser
let mapboxgl;
let ReactMapboxGl = {};

if (typeof window !== "undefined") {
  mapboxgl = require("mapbox-gl");
  ReactMapboxGl = require("react-mapbox-gl");
} else {
  ReactMapboxGl.Map = () => {
    return class Mock extends React.Component {
      constructor() {
        super();
      }
      render() {
        return <div />;
      }
    };
  };
}

const Map = ReactMapboxGl.Map({
  accessToken:
    "pk.eyJ1IjoiYWtnYW5kbHVyIiwiYSI6ImNqbW45N2xodDByZnMza3IyMWNjMGFvM3QifQ.SO15oY4v6GolK3NPWFdRjQ",
});

const { GeoJSONLayer, ZoomControl, Marker } = ReactMapboxGl;

class Maps extends Component {
  state = {
    speed: 1.2,
    loading: false,
    error: false,
    mapFilter: "",
    showSearchNearbyMobile: false,
  };

  componentDidMount() {
    const isIE = /*@cc_on!@*/ false || !!document.documentMode;

    if (isIE) {
      alert(
        "Browser is not supported!!!\n\nDue to technical limitations of the Internet Explorer browser, this website may function poorly.\nKindly switch to Google Chrome, Mozilla Firefox or Microsoft Edge for a better experience."
      );
    }

    const property = this.props.pageContext.property;

    this.setState({
      location: property,
      center: property.location.center,
      zoom: property.location.zoom,
      pitch: property.location.pitch,
      bearing: property.location.bearing,
    });
  }

  getName = () => {
    if (19 < this.state.zoom || this.state.zoom < 15.2) {
      return null;
    }

    return (
      <Marker
        key={"marker"}
        coordinates={this.state.location.nameMarkerLocation}
      >
        <p style={{ fontWeight: 900, color: "#484848" }}>
          {this.state.location.name}
        </p>
      </Marker>
    );
  };

  resetMap = () => {
    if (this.state.mapFilter !== "") return;

    this.map.state.map.flyTo({
      center: this.state.center,
      zoom: this.state.zoom,
      speed: this.state.speed,
      pitch: this.state.pitch,
      bearing: this.state.bearing,
    });
  };

  switchMapFilter = type => {
    this.setState({ mapFilter: type });

    const {
      location: { filters },
      center,
      zoom,
      bearing,
    } = this.state;

    const getMapPosition = () => {
      if (type === "") return { center, zoom, bearing };

      const filter = filters.find(fil => fil.type === type);

      if (typeof window !== `undefined` && window.innerWidth < 992)
        return filter.positions.mobile;

      return filter.positions.web;
    };

    setTimeout(
      () =>
        this.map.state.map.flyTo({
          center: getMapPosition().center,
          zoom: getMapPosition().zoom,
          speed: 2,
          pitch: 0,
          bearing: getMapPosition().bearing,
        }),
      100
    );
  };

  onRemoveFilter = () => {
    this.setState({ mapFilter: "" });

    setTimeout(
      () =>
        this.map.state.map.flyTo({
          center: this.state.center,
          zoom: this.state.zoom,
          speed: this.state.speed,
          pitch: this.state.pitch,
          bearing: this.state.bearing,
        }),
      100
    );
  };

  accessPath = () => {
    if (!this.state.location.accessGeoJson) return null;

    return (
      <GeoJSONLayer
        data={this.state.location.accessGeoJson}
        type="line"
        minZoom={15}
        lineLayout={{
          "line-join": "round",
          "line-cap": "round",
        }}
        linePaint={{
          "line-color": "#FF8A65",
          "line-width": 6,
        }}
      ></GeoJSONLayer>
    );
  };

  searchNearbyButton = () => {
    if (!this.state.location.settings.searchNearby) return null;

    return (
      <HoverLabel
        trigger={
          <Button
            style={{
              position: "absolute",
              top: "11.7rem",
              right: "1rem",
              zIndex: "8",
              boxShadow:
                "0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23)",
              transition: "all 0.7s cubic-bezier(.25,.8,.25,1)",
            }}
            className="searchNearbyButton"
            circular
            icon
            onClick={() => {
              this.setState({
                showSearchNearby: !this.state.showSearchNearby,
                distance: this.state.showSearchNearby
                  ? null
                  : this.state.distance,
              });
            }}
            color={this.state.showSearchNearby ? "grey" : "brown"}
            size="large"
          >
            {this.state.showSearchNearby ? (
              <Icon name="close" />
            ) : (
              <Icon name="search" />
            )}
          </Button>
        }
        size={"mini"}
        content="Get Distance from your Places"
        position={"left center"}
        style={{
          fontWeight: "500",
          opacity: this.state.showSearchNearby ? 0 : 1,
        }}
      />
    );
  };

  searchNearbyButtonMobile = () => {
    if (!this.state.location.settings.searchNearby) return null;

    return (
      <HoverLabel
        trigger={
          <Button
            style={{
              position: "absolute",
              top: "11.7rem",
              right: "1rem",
              zIndex: "8",
              boxShadow:
                "0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23)",
              transition: "all 0.7s cubic-bezier(.25,.8,.25,1)",
            }}
            className="searchNearbyButtonMobile"
            circular
            icon
            onClick={() => {
              this.setState({ showSearchNearbyMobile: true });
            }}
            color={this.state.showSearchNearbyMobile ? "grey" : "brown"}
            size="large"
          >
            <Icon name="search" />
          </Button>
        }
        size={"mini"}
        content="Get Distance from your Places"
        position={"left center"}
        style={{
          fontWeight: "500",
          opacity: this.state.showSearchNearbyMobile ? 0 : 1,
        }}
      />
    );
  };

  closeSearchNearbyModal = () => {
    this.setState({ showSearchNearbyMobile: false });
  };

  searchNearbyModal = () => {
    return (
      <Modal
        dimmer={"inverted"}
        open={this.state.showSearchNearbyMobile}
        size={"mini"}
        onClose={this.closeSearchNearbyModal}
        stye={{ marginTop: 0 }}
        className="search-mobile-modal"
      >
        <Modal.Header>
          Search Nearby
          <Button onClick={this.closeSearchNearbyModal} circular icon="close" />
        </Modal.Header>
        <Modal.Content>
          <p>Find the distance from this property to places you know.</p>
          <div className="search-mobile-container">
            <SearchPanel
              center={this.state.center}
              searchString={this.state.searchStringMobile}
              distanceHandler={this.distanceHandlerMobile}
            />
          </div>
          {this.state.distanceMobile ? (
            <div className="distance-container">
              <p className="distance">{this.state.distanceMobile}</p>
              <p>AWAY</p>
            </div>
          ) : null}
          <div className="close-container">
            <Button
              onClick={this.closeSearchNearbyModal}
              circular
              icon="close"
            />
          </div>
        </Modal.Content>
      </Modal>
    );
  };

  showSearchTextBox = () => {
    if (!this.state.showSearchNearby) return null;

    return (
      <div>
        <SearchPanel
          center={this.state.center}
          searchString={this.state.searchString}
          distanceHandler={this.distanceHandler}
        />
      </div>
    );
  };

  showDistance = () => {
    if (!this.state.distance) return;

    return (
      <div className="distance-container">
        <p className="distance">{this.state.distance}</p>
        <p>AWAY</p>
      </div>
    );
  };

  distanceHandler = distance => this.setState({ distance });

  distanceHandlerMobile = distanceMobile => this.setState({ distanceMobile });

  render() {
    if (this.state.loading) {
      return (
        <div className="loading-page">
          <Helmet>
            <title>helllo</title>
            <meta name="description" content="{description}" />
            <meta name="image" content="{image}" />
            <link rel="canonical" href="{url}" />

            {/* OpenGraph tags */}
            <meta property="og:url" content="{url}" />
            {true ? <meta property="og:type" content="website" /> : null}
            <meta property="og:title" content="{title}" />
            <meta property="og:description" content="{description}" />
            <meta property="og:image" content="{image}" />
          </Helmet>
          <Loader active inline>
            Loading your Experience
          </Loader>
        </div>
      );
    }

    if (this.state.error || !this.state.location) {
      return (
        <div className="loading-page">
          <Helmet>
            <title>helllo</title>
            <meta name="description" content="{description}" />
            <meta name="image" content="{image}" />
            <link rel="canonical" href="{url}" />

            {/* OpenGraph tags */}
            <meta property="og:url" content="{url}" />
            {true ? <meta property="og:type" content="website" /> : null}
            <meta property="og:title" content="{title}" />
            <meta property="og:description" content="{description}" />
            <meta property="og:image" content="{image}" />
          </Helmet>
          <p>Oops! An Error Occured</p>
        </div>
      );
    }

    return (
      <div className="maps">
        <Helmet>
          <title>helllo</title>
          <meta name="description" content="{description}" />
          <meta name="image" content="{image}" />
          <link rel="canonical" href="{url}" />

          {/* OpenGraph tags */}
          <meta property="og:url" content="{url}" />
          {true ? <meta property="og:type" content="website" /> : null}
          <meta property="og:title" content="{title}" />
          <meta property="og:description" content="{description}" />
          <meta property="og:image" content="{image}" />
        </Helmet>
        <Map
          // eslint-disable-next-line
          style="mapbox://styles/mapbox/streets-v9"
          center={this.state.center}
          zoom={[this.state.zoom]}
          pitch={[this.state.pitch]}
          bearing={[this.state.bearing]}
          containerStyle={{
            height: "100%",
            width: "100%",
          }}
          ref={e => {
            this.map = e;
          }}
          className="map-layer"
        >
          {this.getName()}
          {this.accessPath()}
          <GeoJSONLayer
            data={this.state.location.geoJson}
            type="fill-extrusion"
            minZoom={15}
            fillExtrusionPaint={{
              "fill-extrusion-color": "#aaa",
              "fill-extrusion-height": ["*", 4, ["get", "height"]],
              "fill-extrusion-opacity": 0.75,
            }}
          />
          <Markers
            mapFilter={this.state.mapFilter}
            neighbourhood={this.state.location.neighbourhood}
            defaultMarkers={this.state.location.defaultMarkers}
            showDefaultMarkers={this.state.location.settings.showDefaultMarkers}
            homeMarkerLocation={this.state.location.homeMarkerLocation}
          />
          <ZoomControl
            position="bottom-right"
            className="zoom-control"
            zoomDiff={0.5}
          />
        </Map>
        {this.searchNearbyButton()}
        {this.searchNearbyButtonMobile()}
        {this.showSearchTextBox()}
        {this.showDistance()}
        {this.searchNearbyModal()}
        <ResetButton resetMap={this.resetMap} />
        <NavigateButton directions={this.state.location.directions} />
        <HelpButton />
        {typeof window !== `undefined` && window.innerWidth > 992 ? (
          <Image
            src={logo}
            style={{
              position: "absolute",
              top: "1rem",
              left: "1rem",
              height: "3rem",
              zIndex: "9",
            }}
          />
        ) : (
          <Image
            src={mobileLogo}
            style={{
              position: "absolute",
              top: "1rem",
              left: "1rem",
              height: "3rem",
              zIndex: "9",
            }}
          />
        )}
        <MapSidebar
          location={this.state.location}
          onFilterSelect={this.switchMapFilter}
          mapFilter={this.state.mapFilter}
          onRemoveFilter={this.onRemoveFilter}
        />
      </div>
    );
  }
}

export default Maps;

// <GatsbySeo
//           title={`Sthala | ${this.props.pageContext.property.name}`}
//           description={this.props.pageContext.property.description}
//           canonical="http://sthala-demo.s3-website.ap-south-1.amazonaws.com/"
//           openGraph={{
//             url: `http://sthala-demo.s3-website.ap-south-1.amazonaws.com/maps/${this.props.pageContext.property.id}`,
//             title: this.props.pageContext.property.name,
//             description: this.props.pageContext.property.description,
//             site_name: "Sthala",
//           }}
//         />
