import React, { Component } from "react";

import DAO from "src/utils/dao";

import "./theme.scss";

const dao = new DAO();

class SearchPanel extends Component {
  state = {
    predictions: [],
    searchString: "",
  };

  convertCenterToLatLng = center => {
    if (center.length !== 2) return;
    return center[1].toString().concat(",", center[0]);
  };

  handlePropertyClick = (name, placeId) => {
    this.setState({ predictions: [], searchString: name });

    const { center, distanceHandler } = this.props;
    const origin = this.convertCenterToLatLng(center);

    dao.getPlace(placeId).then(({ data }) => {
      const { geometry } = data.result;

      if (Object.keys(geometry).length === 0) return;

      const destination = geometry.location.lat
        .toString()
        .concat(",", geometry.location.lng);
      dao.getDistance(origin, destination).then(({ data }) => {
        if (data.rows === 0) return;

        const { distance } = data.rows[0].elements[0];

        distanceHandler(distance.text);
      });
    });
  };

  onSearch = e => {
    const searchString = e.target.value;

    this.setState({ searchString });

    const center = this.convertCenterToLatLng(this.props.center);

    if (searchString.length === 0 && center.length !== 2) return;

    dao.suggestPlaces(searchString, center).then(({ data }) => {
      const predictions = data.predictions;
      this.setState({ predictions });
    });
  };

  render() {
    return (
      <div className="search-panel">
        <div className="search-bar">
          <input
            type="text"
            value={this.state.searchString}
            onChange={this.onSearch}
            placeholder="Search"
            ref={this.searchBox}
          />
        </div>
        {this.state.predictions.map((location, i) => (
          <div
            className="search-result"
            onClick={() =>
              this.handlePropertyClick(
                location.structured_formatting.main_text,
                location.place_id
              )
            }
            key={`search-results-${i}`}
          >
            <p className="title">{location.structured_formatting.main_text}</p>
            <p className="description">
              {location.structured_formatting.secondary_text}
            </p>
          </div>
        ))}
      </div>
    );
  }
}

export default SearchPanel;
