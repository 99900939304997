import React, { Component } from "react";
import mouse from "src/images/mouse.png";
// import leftmouse from 'images/leftmouse.png';

import { Button, Divider, Icon, Image, Modal, Popup } from "semantic-ui-react";

import "./theme.scss";

class HelpButton extends Component {
  state = {
    showHelp: false,
  };

  closeHelpModal = () => {
    this.setState({ showHelp: false });
  };

  helpModal = () => {
    return (
      <Modal
        dimmer={"blurring"}
        open={this.state.showHelp}
        size={"mini"}
        onClose={this.closeHelpModal}
        style={{ marginTop: "0" }}
        className="help-modal"
      >
        <Modal.Header>Help</Modal.Header>
        <Modal.Content>
          <div className="web-view">
            <div className="section">
              <p className="header">Rotate Map</p>
              <div className="sub-section">
                <Image src={mouse} size={"mini"} />
                <div className="description">
                  Click and drag the Right Mouse Button to pitch or rotate the
                  map.
                </div>
              </div>
            </div>
            {/*<Divider />
                      <div className="section">
                          <p className="header">Click Buildings</p>
                          <div className="sub-section">
                              <Image src={leftmouse} size={'mini'}/>
                              <div className="description">
                                  Click on any building to bring up more information about it.
                              </div>
                          </div>
                      </div>*/}
            <Divider />
            <div className="section">
              <p className="header">Image Viewer</p>
              <div className="sub-section">
                <Icon name="image" size={"big"} />
                <div className="description">
                  Click on any image to open it up in full-view.
                </div>
              </div>
            </div>
          </div>
          <div className="mobile-view">
            <div className="section">
              <p className="header">Rotate Map</p>
              <div className="sub-section">
                <div className="description">
                  Use two finger pinch to rotate and pitch the map.
                </div>
              </div>
            </div>
            {/*<Divider />
                      <div className="section">
                          <p className="header">Tap Buildings</p>
                          <div className="sub-section">
                              <div className="description">
                                  Tap on any building to bring up more information about it.
                              </div>
                          </div>
                      </div>*/}
            <Divider />
            <div className="section">
              <p className="header">Image Viewer</p>r
              <div className="sub-section">
                <div className="description">
                  Tap on any image to open it up in full-view.
                </div>
              </div>
            </div>
          </div>
        </Modal.Content>
        <Modal.Actions>
          <Button onClick={this.closeHelpModal}>Close</Button>
        </Modal.Actions>
      </Modal>
    );
  };

  helpButton = () => {
    return (
      <Popup
        trigger={
          <Button
            style={{
              position: "absolute",
              top: "1rem",
              right: "1rem",
              zIndex: "4",
              boxShadow:
                "0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23)",
              transition: "all 0.7s cubic-bezier(.25,.8,.25,1)",
            }}
            className="helpButton"
            circular
            icon
            color="grey"
            size="large"
            onClick={() => this.setState({ showHelp: true })}
          >
            <Icon name="help" />
          </Button>
        }
        size={"mini"}
        content="Show Help"
        position={"left center"}
        style={{
          fontWeight: "500",
        }}
      />
    );
  };

  render() {
    return (
      <React.Fragment>
        {this.helpButton()}
        {this.helpModal()}
      </React.Fragment>
    );
  }
}

export default HelpButton;
