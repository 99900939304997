import React, { PureComponent } from "react";

import { Table, Tab, Menu } from "semantic-ui-react";

import "./theme.scss";

class OccupancySection extends PureComponent {
  getAccent = value => {
    if (!value) return "";
    if (value === "vacant") {
      return "green";
    }
    if (value === "Under Advanced Discussion") {
      return "process";
    }
    if (value === "Consulting Firm") {
      return "consulting";
    }
    return "";
  };

  getTable = floors => {
    return (
      <Table compact unstackable textAlign={"center"}>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>Floors</Table.HeaderCell>
            <Table.HeaderCell>Area</Table.HeaderCell>
          </Table.Row>
        </Table.Header>

        <Table.Body>
          {floors.map((floor, i) => (
            <Table.Row key={`floor-${i}`}>
              <Table.Cell>{floor.name}</Table.Cell>
              <Table.Cell className={this.getAccent(floor.type)}>
                {floor.size}
              </Table.Cell>
            </Table.Row>
          ))}
        </Table.Body>
      </Table>
    );
  };

  getKey = () => {
    if (!this.props.occupancy.keys) return;

    return (
      <div className="key-collection">
        {this.props.occupancy.keys.map((key, i) => (
          <div className="key" key={`key-${i}`}>
            <div className={`color-patch ${key.type}`} />
            <div>{key.label}</div>
          </div>
        ))}
      </div>
    );
  };

  getPanes = () => {
    return this.props.occupancy.content.map((tower, i) => {
      return {
        menuItem: (
          <Menu.Item
            key={`towers-${i}`}
            className="tab-item"
            style={{ position: "relative" }}
          >
            {tower.name}
          </Menu.Item>
        ),
        render: () => (
          <Tab.Pane attached={false}>
            {this.getTable(tower.floors)}
            {this.getKey()}
          </Tab.Pane>
        ),
      };
    });
  };

  render() {
    if (
      !this.props.open ||
      !this.props.occupancy ||
      !this.props.occupancy.content
    )
      return null;

    if (this.props.occupancy.content.length === 1) {
      return (
        <div className="occupancy-section">
          {this.getTable(this.props.occupancy.content[0].floors)}
          {this.getKey()}
        </div>
      );
    }

    return (
      <Tab
        menu={{ secondary: true }}
        panes={this.getPanes()}
        className="occupancy-section tower-tabs"
      />
    );
  }
}

export default OccupancySection;
