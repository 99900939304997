import React, { Component } from "react";

import { Button, Divider, Icon, Image, Modal, Popup } from "semantic-ui-react";
import times from "src/images/timessymbol.png";

import ImageViewer from "src/components/ImageViewer";
import ImageGallery from "src/components/ImageGallery";
import OccupancySection from "src/components/OccupancySection";
import HighlightsSection from "src/components/HighlightsSection";

import "./theme.scss";

class MapSidebar extends Component {
  state = {
    occupancy: false,
    features: false,
    imageOnDisplay: null,
    openGallery: false,
    screen: "",
    builderModalOpen: false,
  };

  componentWillReceiveProps(newProps) {
    if (newProps.mapFilter !== "") this.switchScreen("filters");
  }

  switchScreen = screen => this.setState({ screen });

  getScreenName = () => {
    switch (this.state.screen) {
      case "occupancy":
        return "OCCUPANCY";
      case "highlights":
        return "HIGHLIGHTS";
      case "neighbourhood":
        return "NEIGHBOURHOOD";
      default:
        return "";
    }
  };

  removeFilters = () => {
    this.switchScreen("");
    this.props.onRemoveFilter();
  };

  details = () => {
    const {
      name,
      builder,
      address,
      city,
      state,
      pincode,
    } = this.props.location;

    if (this.state.screen === "filters") {
      return (
        <div className="details">
          {this.props.location.settings.showName ? (
            <p className="property-name">{name}</p>
          ) : null}
          {this.mapFilters()}
        </div>
      );
    }

    if (this.state.screen !== "") {
      return (
        <div className="details">
          {this.props.location.settings.showName ? (
            <p className="property-name">{name}</p>
          ) : null}
          <p className="occupancy">{this.getScreenName()}</p>
          <Button
            basic
            onClick={() => this.switchScreen("")}
            className="close-button"
            style={{ backgroundColor: "#fff" }}
          >
            <Image src={times} />
          </Button>
        </div>
      );
    }

    return (
      <div className="details">
        {this.props.location.settings.showName ? (
          <p className="property-name">{name}</p>
        ) : null}
        {this.props.location.settings.showBuilder ? (
          <p
            className="builder"
            onClick={() => this.setState({ builderModalOpen: true })}
          >
            {builder}
          </p>
        ) : null}
        <p className="address">{address}</p>
        <p className="city">
          {city}, {state} - {pincode}
        </p>
      </div>
    );
  };

  access = () => {
    const { accessDistances } = this.props.location;

    return (
      <React.Fragment>
        <Divider horizontal key="access-divider">
          ACCESS
        </Divider>
        <div className="access" key="access-content">
          {accessDistances.map(accessPoint => (
            <div className="item" key={`access-${accessPoint.label}`}>
              <Popup
                trigger={<Icon name={accessPoint.icon} size="large" />}
                size={"mini"}
                content={accessPoint.label}
                position={"bottom center"}
                style={{
                  fontWeight: "500",
                }}
              />
              <span className="label">{accessPoint.distance}</span>
            </div>
          ))}
        </div>
      </React.Fragment>
    );
  };

  mapFilters = () => {
    return (
      <React.Fragment>
        <Divider
          horizontal
          className="map-filter-divider"
          key="map-filter-divider"
        >
          NEIGHBOURHOOD
        </Divider>
        <p className="filter-hint">CLICK ON ICONS TO VIEW MORE</p>
        <div className="map-filters" key="map-filter-content">
          {this.props.location.filters.map(filter => (
            <div className="item" key={`filter-${filter.type}`}>
              <Popup
                trigger={
                  <div
                    className={`filter-button ${filter.type} ${
                      this.props.mapFilter === filter.type ? "on-focus" : ""
                    }`}
                    onClick={() => this.props.onFilterSelect(filter.type)}
                  >
                    <Icon
                      name={filter.icon}
                      size="large"
                      style={{ fontSize: "1.2rem" }}
                    />
                  </div>
                }
                size={"mini"}
                content={filter.label}
                position={"bottom center"}
                style={{
                  fontWeight: "500",
                }}
              />
              {this.props.mapFilter === filter.type ? (
                <span className="label">{filter.label.toUpperCase()}</span>
              ) : null}
            </div>
          ))}
        </div>
        {this.props.mapFilter !== "" ? (
          <div className="remove-filter-container">
            <Button
              basic
              onClick={this.removeFilters}
              className="remove-filter-button"
            >
              <Image src={times} /> Remove Filters
            </Button>
          </div>
        ) : null}
      </React.Fragment>
    );
  };

  neighbourhoodList = () => {
    if (this.state.screen !== "neighbourhood") return;

    return (
      <HighlightsSection
        open={this.state.screen === "neighbourhood"}
        highlights={this.props.location.sections.neighbourhood}
      />
    );
  };

  highlights = () => {
    if (this.state.screen !== "highlights") return;

    return (
      <HighlightsSection
        open={this.state.screen === "highlights"}
        highlights={this.props.location.sections.highlights}
      />
    );
  };

  imageList = () => {
    const { images } = this.props.location;

    return [
      <Divider horizontal key="images-divider">
        IMAGES
      </Divider>,
      <div className="image-list web" key="images-list-1">
        {images.slice(0, 3).map((img, i) => (
          <div
            className="image"
            key={i}
            style={{ backgroundImage: `url(${img.url})` }}
            onClick={() =>
              this.setState({ imageOnDisplay: img, imageIndex: i })
            }
          >
            <div className="filter">
              <div className="name">{img.name}</div>
              {img.type === "video" ? (
                <div className="video-icon">
                  <Icon name="play circle" size={"huge"} />
                </div>
              ) : null}
            </div>
          </div>
        ))}
        {images.length === 3 ? (
          <div className="placeholder" key="placeholder" />
        ) : null}
        {images.length > 3 ? (
          <div
            className="more-images"
            key="more-image"
            onClick={() => this.setState({ openGallery: true })}
          >
            <Icon size="big" name="plus" />
            <p>{images.length - 3} More</p>
          </div>
        ) : null}
      </div>,
      <div className="image-list mobile" key="images-list-2">
        {images.map((img, i) => (
          <div
            className="image"
            key={i}
            style={{ backgroundImage: `url(${img.url})` }}
            onClick={() =>
              this.setState({ imageOnDisplay: img, imageIndex: i })
            }
          >
            <div className="filter">
              <div className="name">{img.name}</div>
              {img.type === "video" ? (
                <div className="video-icon">
                  <Icon name="play circle" size={"huge"} />
                </div>
              ) : null}
            </div>
          </div>
        ))}
        {images.length % 2 !== 0 ? (
          <div className="placeholder" key="placeholder" />
        ) : null}
      </div>,
    ];
  };

  getDefaultScreen = () => {
    if (this.state.screen !== "") return null;

    return (
      <React.Fragment>
        {this.props.location.sections.highlights ? (
          <Button
            className="occupancy-button"
            size="mini"
            onClick={() => this.switchScreen("highlights")}
          >
            HIGHLIGHTS
          </Button>
        ) : null}
        {this.props.location.sections.neighbourhood ? (
          <Button
            className="occupancy-button"
            size="mini"
            onClick={() => this.switchScreen("neighbourhood")}
          >
            NEIGHBOURHOOD
          </Button>
        ) : null}
        {this.props.location.sections.occupancy ? (
          <Button
            className="occupancy-button"
            size="mini"
            onClick={() => this.switchScreen("occupancy")}
          >
            {this.props.location.sections.occupancy.name.toUpperCase()}
          </Button>
        ) : null}
        {this.access()}
        {this.mapFilters()}
        {this.imageList()}
      </React.Fragment>
    );
  };

  onBuilderModalClose = () => this.setState({ builderModalOpen: false });

  builderModal = () => {
    return (
      <Modal
        dimmer={"blurring"}
        open={this.state.builderModalOpen}
        onClose={this.onBuilderModalClose}
        style={{ marginTop: "0" }}
        size="tiny"
        className="builder-modal"
      >
        <Modal.Content>
          <Image
            src={this.props.location.builderLogo}
            centered
            style={{ height: "6rem", marginBottom: "1.5rem" }}
          />
          <div
            dangerouslySetInnerHTML={{
              __html: this.props.location.builderDescription,
            }}
          />
        </Modal.Content>
        <Modal.Actions>
          <Button onClick={this.onBuilderModalClose}>Close</Button>
        </Modal.Actions>
      </Modal>
    );
  };

  sthalaBranding = () => {
    return (
      <div className="sthala-branding">
        <p>
          Made with <a href="/">Sthala</a>
        </p>
      </div>
    );
  };

  render() {
    if (!this.props.location) {
      return null;
    }

    return (
      <div
        className={`map-info-panel ${
          this.state.screen !== "" ? "stretchSidebar" : ""
        }`}
      >
        <div className="broker">
          <Image
            style={{
              marginLeft:
                this.props.location.logo.align === "left" ? "1rem" : 0,
            }}
            src={this.props.location.logo.url}
            height={this.props.location.logo.height}
          />
        </div>
        <div className="content">
          {this.details()}
          {this.getDefaultScreen()}
          <OccupancySection
            open={this.state.screen === "occupancy"}
            occupancy={this.props.location.sections.occupancy}
          />
          {this.highlights()}
          {this.neighbourhoodList()}
        </div>
        <ImageViewer
          open={!!this.state.imageOnDisplay}
          imageIndex={this.state.imageIndex}
          imagesList={this.props.location.images}
          onClose={() => this.setState({ imageOnDisplay: null })}
        />
        <ImageGallery
          open={this.state.openGallery}
          images={this.props.location.images}
          onSelect={(image, index) =>
            this.setState({ imageOnDisplay: image, imageIndex: index })
          }
          onClose={() => this.setState({ openGallery: null })}
        />
        {this.builderModal()}
        {this.state.screen !== "" ||
        !this.props.location.downloadBrochure ? null : (
          <div className="download-assets">
            <Icon name="download" />
            <a
              target="_blank"
              rel="noopener noreferrer"
              href={this.props.location.downloadBrochure}
            >
              Download Presentation
            </a>
          </div>
        )}
        {this.sthalaBranding()}
      </div>
    );
  }
}

export default MapSidebar;
