import React, { PureComponent } from "react";

import { Table, Tab, Menu } from "semantic-ui-react";

import "./theme.scss";

class HighlightsSection extends PureComponent {
  getTable = features => {
    return (
      <Table compact unstackable>
        <Table.Body>
          {features.map((feature, i) => (
            <Table.Row key={`feature-${i}`} verticalAlign="top">
              <Table.Cell className="header">{feature.name}</Table.Cell>
              <Table.Cell>
                {feature.description.map((item, i) => (
                  <p key={`${item}-${i}`}>{item}</p>
                ))}
              </Table.Cell>
            </Table.Row>
          ))}
        </Table.Body>
      </Table>
    );
  };

  getPanes = () => {
    return this.props.highlights.content.map((highlight, i) => {
      return {
        menuItem: (
          <Menu.Item
            key={`highlight-${i}`}
            className="tab-item"
            style={{ position: "relative" }}
          >
            {highlight.name}
          </Menu.Item>
        ),
        render: () => (
          <Tab.Pane attached={false}>
            {this.getTable(highlight.features)}
          </Tab.Pane>
        ),
      };
    });
  };

  render() {
    if (
      !this.props.open ||
      !this.props.highlights ||
      !this.props.highlights.content
    )
      return null;

    if (this.props.highlights.content.length === 1) {
      return (
        <div className="highlights-section">
          {this.getTable(this.props.highlights.content[0].features)}
        </div>
      );
    }

    return (
      <Tab
        menu={{ secondary: true }}
        panes={this.getPanes()}
        className="highlights-section highlight-tabs"
      />
    );
  }
}

export default HighlightsSection;
