import React, { Component } from "react";

import { Button, Icon, Modal } from "semantic-ui-react";

import "./theme.scss";

class ImageGallery extends Component {
  render() {
    const { open, images, onSelect, onClose } = this.props;

    if (!images || images.length < 1) return null;

    return (
      <Modal
        dimmer={"blurring"}
        open={open}
        onClose={onClose}
        style={{ marginTop: "0" }}
        className="image-gallery"
      >
        <Modal.Header>Gallery</Modal.Header>
        <Modal.Content>
          {images.map((image, i) => (
            <div
              className="image"
              key={i}
              style={{ backgroundImage: `url(${image.url})` }}
              onClick={() => onSelect(image, i)}
            >
              <div className="filter">
                <div className="name">{image.name}</div>
                {image.type === "video" ? (
                  <div className="video-icon">
                    <Icon name="play circle" size={"huge"} />
                  </div>
                ) : null}
              </div>
            </div>
          ))}
        </Modal.Content>
        <Modal.Actions>
          <Button onClick={onClose}>Close</Button>
        </Modal.Actions>
      </Modal>
    );
  }
}

export default ImageGallery;
