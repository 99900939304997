import React, { Component } from "react";
import { Button, Popup as HoverLabel } from "semantic-ui-react";

import directionIcon from "src/images/directions.png";

import "./theme.scss";

class NavigateButton extends Component {
  getDirections = () => {
    if (
      typeof window !== `undefined` &&
      navigator.platform.indexOf("iPhone") !== -1
    ) {
      return window.open(this.props.directions.ios);
    } else {
      return window.open(this.props.directions.android);
    }
  };

  render() {
    return (
      <HoverLabel
        trigger={
          <Button
            style={{
              position: "absolute",
              top: "8rem",
              right: "1rem",
              zIndex: "6",
              boxShadow:
                "0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23)",
              transition: "all 0.7s cubic-bezier(.25,.8,.25,1)",
              padding: "0.49rem",
            }}
            className="navigateButton"
            circular
            icon
            onClick={this.getDirections}
            color={"teal"}
            size="large"
          >
            <img
              src={directionIcon}
              style={{ width: "2rem" }}
              alt="Get Directions"
            />
          </Button>
        }
        size={"mini"}
        content="Get Directions"
        position={"left center"}
        style={{
          fontWeight: "500",
        }}
      />
    );
  }
}

export default NavigateButton;
