import React, { Component } from "react";

import { Button, Icon, Popup as HoverLabel } from "semantic-ui-react";

import "./theme.scss";

class ResetButton extends Component {
  render() {
    return (
      <HoverLabel
        trigger={
          <Button
            style={{
              position: "absolute",
              top: "4.5rem",
              right: "1rem",
              zIndex: "5",
              backgroundColor: "#fff",
              boxShadow:
                "0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23)",
              transition: "all 0.7s cubic-bezier(.25,.8,.25,1)",
            }}
            className="resetButton"
            onClick={this.props.resetMap}
            circular
            icon
            size="large"
          >
            <Icon name="home" />
          </Button>
        }
        size={"mini"}
        content="Reset Map"
        position={"left center"}
        style={{
          fontWeight: "500",
        }}
      />
    );
  }
}

export default ResetButton;
