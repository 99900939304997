import React, { PureComponent } from "react";
import { Marker } from "react-mapbox-gl";
import { Icon } from "semantic-ui-react";
import "mapbox-gl/dist/mapbox-gl.css";

import "./theme.scss";

class Markers extends PureComponent {
  filteredMarkers = () => {
    const { neighbourhood, mapFilter, homeMarkerLocation } = this.props;

    return neighbourhood.reduce(
      (acc, label) => {
        if (mapFilter !== label.type) return acc;

        return [
          ...acc,
          <Marker
            coordinates={label.latlng}
            key={`marker-${label.name}-${label.type}`}
            anchor="center"
          >
            <div className="filter-marker">
              <Icon name={label.icon} size="large" />
              <div className="filter-label">
                <p>{label.name.toUpperCase()}</p>
              </div>
            </div>
          </Marker>,
        ];
      },
      [
        <Marker coordinates={homeMarkerLocation} key={"home"} anchor="center">
          <div className="home-marker">
            <Icon
              name="home"
              size={"large"}
              style={{ color: "#ECEFF1", margin: "0" }}
            />
          </div>
        </Marker>,
      ]
    );
  };

  defaultMarkers = () => {
    return this.props.defaultMarkers.map(label => (
      <Marker
        coordinates={label.latlng}
        key={`marker-${label.name}-${label.type}`}
        anchor="center"
      >
        <div className="filter-marker">
          <Icon name={label.icon} size="large" />
          <div className="filter-label">
            <p>{label.name.toUpperCase()}</p>
          </div>
        </div>
      </Marker>
    ));
  };

  render() {
    const { neighbourhood, mapFilter, showDefaultMarkers } = this.props;

    if (!neighbourhood) return null;

    if (mapFilter) {
      return this.filteredMarkers();
    }

    if (showDefaultMarkers) {
      return this.defaultMarkers();
    }

    return null;
  }
}

export default Markers;
